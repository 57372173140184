import { postAPICall } from "../helpers/httpHelper";
import { db } from "../utils/db";

export const fetchUserTypes = async(params) => {
  let userTypes = await fetchDataFromLocal('user_types');
  if (!userTypes) {
    userTypes = await fetchDataFromDatabase("GET","/userTypeList",params);
  }
  return userTypes;
}

export const fetchDataFromLocal = async (collection) => {
  // if(params && Object.keys(params)?.length) {
  //   return await db.[collection]
  //   .filter((temp) => temp?.entity_id == params.entity_id || temp?.entity_id?._id == params.entity_id)
  //   .toArray();
  // }
  return await db[collection].toArray();
}

export const fetchDataFromDatabase = async (type, url, params) => {
  let response = await postAPICall(
    type,
    url,
    params
  );
  return response?.data;
};

